

const HomeIndex = () =>
	import('../components/HomeIndex')

export default {
	base: '/',
	routes: [{
		path: '/HomeIndex',
		name: 'HomeIndex',
		component: HomeIndex
	}]
}