let lang = {
	"zh":{
		"rank": "排行榜",
		"easyModel": "简单模式",
		"normalModel": "普通模式",
		"hardModel": "困难模式",
		"payGame": "开始游戏",
		"rankId": "排名",
		"name": "地址",
		"length": "长度",
		"recharge": "充值",
		"rechargePla": "请输入您要充值的数量",
		"balance": "余额",
		"claim": "提现",
		"claimPla": "请输入您要提现的数量",
		"claimRecor": "收益记录",
		"myRewarad": "我的收益",
		"rechargeSuccess": "充值成功",
		"rechargeFail": "充值失败",
		"claimSuccess": "提现成功",
		"claimFail": "提现成功",
		"claimAmountError": "可提现数量不足",
		"balanceError": "余额不足",
		"notHaveReward": "当前暂无收益",
		"claimReward": "领取",
		"reward": "收益",
		"rewardSuccess": "领取成功",
		"terming":" 期 进行中",
		"rule": "规则",
		"termid": "期",
		"time": "时间",
		"stoptime": "截止时间",
		"pleaseRecharge": "请先充值",
		"haveclaim": "已领取",
		"jiangli": "奖励",
		"currentPool": "当前奖池",
		"nodata": "暂无数据",
		"claimNav": "提现"
	},
	"en":{
		"rank":"Ranking List",
		"easyModel": "Simple Mode",
		"normalModel": "Normal Mode",
		"hardModel": "Hard Mode",
		"payGame": "Play Game",
		"rankId": "Rank Id",
		"name": "Address",
		"length": "Length",
		"recharge": "Recharge",
		"rechargePla": "Please enter amount ",
		"balance": "Balance",
		"claim": "Withdraw",
		"claimNav": "Claim",
		"claimPla": "Please enter amount",
		"claimRecor": "Withdraw Record",
		"myRewarad": "My Earnings",
		"rechargeSuccess": "Recharge Successful",
		"rechargeFail": "Recharge Failed",
		"claimSuccess": "Withdraw Successful",
		"claimFail": "Withdraw Failed",
		"claimAmountError": "Withdraw Amount Error",
		"balanceError": "Insufficient Balance",
		"notHaveReward": "No Profit",
		"claimReward": "withDraw",
		"reward": "Reward",
		"rewardSuccess": "Claim Successful",
		"terming":"in progress",
		"rule": "Rule",
		"termid": "Term Id",
		"time": "Time",
		"stoptime": "Deadline",
		"pleaseRecharge": "Please recharge first",
		"haveclaim": "claimed",
		"jiangli": "Reward",
		"currentPool": "Current Pool",
		"nodata":"No Data"
		
	}
	
}
export default lang;